import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import NotFound from '~/components/layouts/NotFound'
import { langs } from '~/i18n'
import Landing from './Landing'

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/ru" />
            </Route>
            <Route
                path={ `/:lang(${ langs.join('|') })/` }
                exact
                component={ Landing }
            />
            <Route component={ NotFound }/>+
        </Switch>
    )
}

export default Routes

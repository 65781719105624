import React from 'react'
import { withTranslation } from 'react-i18next'
import PrivateListItem from './PrivateListItem'

export default withTranslation()(({ t, i18n: { language } }) => {
    return (
        <section className="section lc" id="section-lc">
            <div className="container">
                <div className="row lc-wrap">
                    <div className="col-12 col-lg-6">
                        <div className="lc-phone">
                            <img src={language === 'en' ? "/img/phone-app_en.png" : "/img/phone-app.png"} alt="App on phone screen" className='phone-bg'/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="lc-info">
                            <h2 className="h2">
                                { t('landing.private.title') }
                            </h2>
                            <p className="par lc-text">
                                { t('landing.private.description') }
                            </p>
                            <ul className="lc-list">
                                <h3 className="h3">
                                    { t('landing.private.opportunities.title') }:
                                </h3>
                                <PrivateListItem text={t('landing.private.opportunities.o1')} />
                                <PrivateListItem text={t('landing.private.opportunities.o2')} />
                                <PrivateListItem text={t('landing.private.opportunities.o3')} />
                            </ul>
                            <ul className="lc-list">
                                <h3 className="h3">
                                    { t('landing.private.additional.title') }:
                                </h3>
                                <PrivateListItem text={t('landing.private.additional.a1')} />
                                <PrivateListItem text={t('landing.private.additional.a2')} />
                                <PrivateListItem text={t('landing.private.additional.a3')} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
})

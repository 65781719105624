import React from 'react';

const PrivateListItem = ({ text }) => (
<li className="lc-list__item">
  <img src="/img/hexagon.svg" className='lc-list__item_svg' alt='hexagon'/>
  <span>{text}</span>
</li>
);

export default PrivateListItem;

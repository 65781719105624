import React from 'react'
import { withTranslation } from 'react-i18next'

const Footer = ({ t, i18n: { language } }) => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                        <div className="footer-wrap">
                            <div className="footer__logo">
                                <img src="/img/logo2.svg" alt="Logo"/>
                                <div className="logo-text">
                                    {t('landing.footer.tagline')}
                                </div>
                            </div>
                            <div className="footer__info">
                                <div className="footer__info-item">
                                    {t('landing.footer.entityDetails')}
                                </div>
                                {language === 'ru' ? (<div className="footer__info-item_orgnip">
                                    {t('landing.footer.OGRNIP')}
                                </div>) : null}
                                <div className="copy footer__info-item">
                                    Copyright { (new Date()).getYear() + 1900 }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default withTranslation()(Footer)
import React, { useState } from 'react'

export const PopupContext = React.createContext({})

const PopupProvider = ({ children }) => {
    const [ isOpen, setOpenState ] = useState(false)
    const [ popup, setPopup ] = useState(null)

    const showPopup = (popup) => {
        setOpenState(true)
        setPopup(popup)
    }

    const closePopup = () => {
        setOpenState(false)
    }

    const getPopup = () => {
        if (isOpen) return (<div className="overlay" data-popup-overlay onClick={ e => {
                if (e.target.hasAttribute('data-popup-overlay')) closePopup()
            } }>
                { popup }
            </div>)

        return null
    }

    return (<PopupContext.Provider
            value={ {
                showPopup, closePopup
            } }
        >
            { children }

            { getPopup() }
        </PopupContext.Provider>)
}

export default PopupProvider

import React from 'react'
import { withTranslation } from 'react-i18next'
import { withPopup } from '~/components/layouts/Popup'
import { compose } from 'recompose'

const MenuDesktop = ({ t }) => {
    return (
        <div className="header-menu">
            <div className="container ">
                <div className="row">
                    <div className="col-12 header-wrap">
                        <div className="header__logo">
                            <img src="/img/logo2.svg" alt="Logo" />
                            <img src="/img/logo_text.svg" alt="Logo" className="header__logo_text"/>
                        </div>
                        <nav className="nav header__nav">
                            <ul className="nav-list">
                                <li className="nav-list__item">
                                    <a href='#section-get'>
                                        {t('landing.menu.whatYouGet')}
                                    </a>
                                </li>
                                <li className="nav-list__item">
                                    <a href='#section-work'>
                                        {t('landing.menu.howItWorks')}
                                    </a>
                                </li>
                                <li className="nav-list__item">
                                    <a href="#section-lc">
                                        {t('landing.menu.app')}
                                    </a>
                                </li>
                                {/* <li className="nav-list__item">
                                    <a href="#section-benefits">
                                        {t('landing.menu.benefit')}
                                    </a>
                                </li> */}
                                <li className="nav-list__item">
                                    <a href="https://shop.rashidov.su/" target="_blank" rel="noopener noreferrer">
                                        {t('landing.menu.shop')}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default compose(
    withPopup,
    withTranslation()
)(MenuDesktop)

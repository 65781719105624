import React from 'react'
import { withTranslation } from 'react-i18next'

const WhatYouReceive = ({ t, i18n: { language } }) => {
    return (
        <section className="section get" id="section-get">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="h2">
                            {t('landing.whatYouReceive.title')}
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="get-item">
                            <div className="get-item__img">
                                <img src="/img/get-1.png" alt="get-1"/>
                            </div>
                            <h3 className="get-item__title h3">
                                {t('landing.words.scale')}
                            </h3>
                            <p className="get-item__text">
                                {t('landing.whatYouReceive.scaleDescription')}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="get-item">
                            <div className="get-item__img">
                                <img src="/img/get-2.png" alt="get-2"/>
                            </div>
                            <h3 className="get-item__title h3">
                                {t('landing.whatYouReceive.digitalStation')}
                            </h3>
                            <p className="get-item__text">
                                {t('landing.whatYouReceive.digitalStationDescription')}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="get-item">
                            <div className="get-item__img">
                                {language === 'en' ? <img src="/img/get-4_en.png" alt="get-4"/> : <img src="/img/get-4.png" alt="get-4"/>}
                            </div>
                            <h3 className="get-item__title h3">
                                {t('landing.whatYouReceive.software')}
                            </h3>
                            <p className="get-item__text">
                                {t('landing.whatYouReceive.softwareDescription')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(WhatYouReceive)
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withPopup } from '~/components/layouts/Popup'
import { compose } from 'recompose'

const MenuMobile = ({ t, i18n: { language } }) => {
    const [isOpen, setOpenState] = useState(false);

    const handleClick = () => {
        setOpenState(!isOpen);
    };

    const MenuItem = ({ text, sectionId }) => (
        <div className="mob-menu__item">
            <a href={`#${sectionId}`}>
                {text}
            </a>
        </div>
    ) 

    const email = `info@rashidov.${language === 'en' ? 'eu' : 'su'}`

    return (
        <>
            <div className={`mob-menu ${isOpen ? 'mob-menu_active' : 'mob-menu_closed'}`}>
                <div className="mob-menu__body">
                    <MenuItem text={t('landing.menu.whatYouGet')} sectionId={'section-get'} className="mob-menu__item"/>
                    <MenuItem text={t('landing.menu.howItWorks')} sectionId={'section-work'} />
                    <MenuItem text={t('landing.menu.app')} sectionId={'section-lc'} />
                    {/* <MenuItem text={t('landing.menu.benefit')} sectionId={'section-benefits'} /> */}
                    <div className="mob-menu__item">
                        <a href="https://shop.rashidov.su/" target="_blank" rel="noopener noreferrer">
                            {t('landing.menu.shop')}
                        </a>
                    </div>
                </div>
                <div className="mob-menu__footer">
                    {language === 'en' ? null : (
                    <div className="mob-menu__footer_item">
                        <img src="/img/phone-ico.png" alt="Ico"/>
                        <a href="tel://+78422505504">
                            +7 (842) 250-55-04
                        </a>
                    </div>
                    )}
                    <div className="mob-menu__footer_item">
                        <img src="/img/email-ico.png" alt="Ico"/>
                        <a href={`mailto:${email}`}>
                            {email}
                        </a>
                    </div>
                    <div className="mob-menu__footer_item">
                        <div>
                            <div className="mob-menu__footer__info">
                                { t('landing.footer.entityDetails') }
                            </div>
                            <div className="copy mob-menu__footer__info">
                                Copyright { (new Date()).getYear() + 1900 }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`menu-button_container ${isOpen ? 'menu-button_container_active' : 'menu-button_container_closed'}`}>
                <button className={`menu-button ${isOpen ? 'menu-button_active' : 'menu-button_closed'}`} onClick={handleClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </>
    )
}

export default compose(
    withPopup,
    withTranslation()
)(MenuMobile)

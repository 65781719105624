import React from 'react'
import withPopup from './withPopup'

const Popup = ({ children, title, closePopup }) => {
    return (<div className="popup">
        <div className="popup__top">
            <div className="popup__top__center">
                <img src="/img/popup-header-left.svg" alt="Bg"
                     className="popup__top__center__bg popup__top__center__bg_left"/>
                <img src="/img/popup-header-right.svg" alt="Bg"
                     className="popup__top__center__bg popup__top__center__bg_right"/>
                <h2 className="h2">
                    { title }
                </h2>
            </div>
            <img src="/img/cross.png" alt="Close" className="popup__top__close" onClick={ closePopup }/>
        </div>
        <div className="popup-body popup-body_one-elem">
            { children }
        </div>
    </div>)
}

export default withPopup(Popup)

export { default as PopupProvider } from './PopupProvider'
export { default as withPopup } from './withPopup'

import React from 'react'
import { withTranslation } from 'react-i18next'
import { withPopup } from '~/components/layouts/Popup'
import { compose } from 'recompose'
import OrderButton from '../../../ui/OrderButton'

const Main = ({ t }) => {
    return (<div className='header-banner'>
        <div className='container '>
            <div className='row'>
                <div className='col-12 col-lg-4'>
                    <div className='header-cta'>
                        <h1 className='h1 header__h1'>
                            { t('landing.words.scale') }. <br />
                            { t('landing.words.forBeekeepers') }. <br />
                            { t('landing.words.wireless') }.
                        </h1>
                    </div>
                    <div className='header__steps'>
                        <OrderButton />
                    </div>
                </div>
                <div className='col-12 offset-lg-1 col-lg-7 header-video-wrap'>
                    <div className='header-video'>
                        <img src='/img/beehives.png' alt='beehives'>
                        </img>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default compose(withTranslation(), withPopup)(Main)

import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

const benefitsList = [ {
    name: 'support', ico: 'ben1.png', count: 2
}, {
    name: 'technologies', ico: 'ben2.png', count: 4
}, {
    name: 'scalability', ico: 'ben3.png', count: 2
}, {
    name: 'private', ico: 'ben4.png', count: 2
}, {
    name: 'battery', ico: 'ben5.png', count: 2
}, {
    name: 'signalQuality', ico: 'ben6.png', count: 3
}, {
    name: 'eco', ico: 'ben7.png', count: 2
} ]

const Benefits = ({ t }) => {
    const [ activeItem, setActiveItem ] = useState(0)

    return (<section className="section benefits" id="section-benefits">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="h2">
                        { t('landing.benefits.title') }
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-10 offset-lg-1">
                    <div className="benefits-wrap">
                        { benefitsList.map(({ name, ico, count }, index) => (<div
                            className={ 'benefits-item ' + (index === activeItem ? 'benefits-item_active' : '') }
                            key={ name }
                            onClick={ () => {
                                setActiveItem(index)
                            } }>
                            <div className="benefits-item__top">
                                <div className="benefits-item__ico">
                                    <img src={ `/img/${ ico }` } alt={ name }/>
                                </div>
                                <div className="benefits-item__title">
                                    <h3 className="h3">
                                        { t(`landing.benefits.${ name }.title`) }
                                    </h3>
                                </div>
                            </div>
                            <div className="benefits-item__body">
                                { [ ...Array(count) ].map((value, index) => (<p className="par" key={ index }>
                                    { t(`landing.benefits.${ name }.p${ index + 1 }`) }
                                </p>)) }
                            </div>
                        </div>)) }
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default withTranslation()(Benefits)
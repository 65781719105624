import React from 'react'
import Routes from '~/components/layouts/Routes'
import { I18nextProvider } from 'react-i18next'
import { PopupProvider } from '~/components/layouts/Popup'

function App({ url, context, i18n, Router }) {
    return (<I18nextProvider i18n={ i18n }>
        <PopupProvider>
            <Router location={ url } context={ context }>
                <Routes/>
            </Router>
        </PopupProvider>
    </I18nextProvider>)
}

export default App

import React from 'react'
import { withTranslation } from 'react-i18next'

const HowItWorks = ({ t, i18n: { language } }) => {
    console.log(language === 'en');
    return (
        <section className="section work" id="section-work">
            <div className="container">
                <div className="row work-wrap">
                    <div className="col-12 col-lg-6">
                        <div className="work-info">
                            <h2 className="h2">
                                {t('landing.howItWorks.title')}
                            </h2>
                            <div className="work-chart d-block d-lg-none">
                                <img src={language === 'en'? "/img/scheme_en.png" : "/img/scheme.png"} alt="scheme"/>
                            </div>
                            <p className="par">
                                {t('landing.howItWorks.p1')}
                                <b>Bluetooth Low Energy</b>.
                            </p>
                            <p className="par">
                                {t('landing.howItWorks.p2_1')}
                                <b>{t('landing.howItWorks.unlimitedCountOfScales')}</b>
                                {t('landing.howItWorks.p2_2')}
                            </p>
                            <p className="par">
                                <b>{t('landing.howItWorks.youAreAbleToSeeGates')}</b>
                                {t('landing.howItWorks.p3')}
                            </p>
                            {/*<button className="btn btn_ico btn_black">*/}
                            {/*    <img src="/img/play-ico.png" alt="Ico"/>*/}
                            {/*    <span>*/}
                            {/*        {t('landing.howItWorks.watchVideo')}*/}
                            {/*    </span>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-none d-lg-block">
                        <div className="work-chart">
                        <img src={language === 'en'? "/img/scheme_en.png" : "/img/scheme.png"} alt="scheme"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(HowItWorks)
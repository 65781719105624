import React from 'react'
import { withTranslation } from 'react-i18next'

const OrderButton = ({ t }) => (
  <a href="https://shop.rashidov.su/" target="_blank" rel="noopener noreferrer">
    <button className='btn btn_ico btn_black'>
      <img src='/img/shop-ico.svg' alt='Ico' />
      <span>
        { t('landing.words.order') }
      </span>
    </button>
  </a>
)

export default withTranslation()(OrderButton)
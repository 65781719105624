import React from 'react'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import SectionMain from '../sections/Main'

const Header = () => (
<>
    <header className="header">
        <MenuDesktop />
        <SectionMain />
    </header>
    <MenuMobile />
</>
)

export default Header

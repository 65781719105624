import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Footer from './Footer'
import Header from './Header'
import WhatYouReceive from './sections/WhatYouReceive'
import HowItWorks from './sections/HowItWorks'
import Private from './sections/Private'
import Benefits from './sections/Benefits'
import Contacts from './sections/Contacts'
import Popup, { withPopup } from '~/components/layouts/Popup'
import { compose } from 'recompose'
import { parse } from 'query-string'

const Landing = ({ showPopup, location: { search }, t }) => {
    useEffect(() => {
        const { popup } = parse(search)

        if (popup === 'payment_confirmation') {
            showPopup(<Popup title="Оплачено">
                <div className="popup-body-center">
                    <h2>
                        Спасибо, что выбрали нас <span role="img" aria-label="smile">😊</span><br /><br />
                        Мы свяжемся с вами, как только ваш заказ будет готов <span role="img" aria-label="bee">🐝</span>
                    </h2>
                </div>
            </Popup>)
        }
    })

    useEffect(() => {
        document.title = t('Title');
    }, [ t ])

    return (<Fragment>
        <div className="content">
            <Header />

            <WhatYouReceive />

            <HowItWorks />

            <Private />

            <Benefits />

            <div className="line">
            </div>

            <Contacts />
        </div>
        <Footer />
    </Fragment>)
}

export default compose(withPopup, withTranslation())(Landing)
import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { langs } from './index'

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
//.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to the react-i18next components.
    // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        //debug: true,

        // interpolation: {
        //     escapeValue: false, // not needed for react as it escapes by default
        // },

        //lng: "ru",

        resources: langs.reduce(
            (prev, current) => ({
                [current]: {
                    translation: require(`./${ current }.json`)
                },
                ...prev
            }), {}),

        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            wait: true,
        },

        detection: {
            order: ['cookie'],
            lookupCookie: 'lng'
        }
    })

export default i18n
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

const NotFound = ({ t, staticContext }) => {
    if (staticContext)
        staticContext.statusCode = 404

    return (
        <Fragment>
            <h1>
                404 - { t(`PageNotFound`) }
            </h1>
        </Fragment>
    )
}

export default withTranslation()(NotFound)
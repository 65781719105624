import React from 'react'
import { PopupContext } from './PopupProvider'

const withPopup = Component => class WithPopup extends React.Component {
    static contextType = PopupContext

    render() {
        return <Component { ...this.props } { ...this.context } />
    }
}

export default withPopup
